import React, { FC } from "react";

interface ProgressStepperProps {
  progress: number; // Progress as a percentage (0-100)
  className?: string;
}

const ProgressStepper: FC<ProgressStepperProps> = ({ progress, className }) => {
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="h-full bg-[#c49f2a] transition-all duration-300 ease-out"
          style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressStepper;
