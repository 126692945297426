import { useEffect, useState } from "react";
import {
  submitFitFinderResponse,
  submitPreferredAndGetNextImages,
  getPreferencesString,
  onFitFinderGameComplete,
} from "../services/fitFinderService";
import { toast } from "react-toastify";

import { useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

const useFitFinder = () => {
  const posthog = usePostHog();

  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStage, setCurrentStage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [image1Id, setImage1Id] = useState("");
  const [image2Id, setImage2Id] = useState("");
  const [image1Base64, setImage1Base64] = useState("");
  const [image2Base64, setImage2Base64] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [image1MostRcentlyClicked, setImage1MostRcentlyClicked] =
    useState(false);
  const [preferencesString, setPreferencesString] = useState<string | null>(
    null
  );
  const [comparisonsCount, setComparisonsCount] = useState(0);

  // useEffect(() => {
  //   if (currentStep >= TOTAL_STEP) {
  //     setIsFinished(true);
  //     if (searchParams.get("sessionId")) {
  //       onFitFinderGameComplete(searchParams.get("sessionId") as string).catch(
  //         (error) =>
  //           console.error("Error calling onFitFinderGameComplete:", error)
  //       );
  //     }
  //   }
  // }, [currentStep, searchParams]);

  useEffect(() => {
    const initializeFitFinder = async () => {
      setIsLoading(true);
      try {
        if (!searchParams.get("sessionId")) {
          throw new Error("Invalid session ID");
        }
        const initialImages = await submitPreferredAndGetNextImages(
          searchParams.get("sessionId") as string,
          null,
          null
        );
        if (initialImages.image1_id === null) {
          setIsFinished(true);
          toast.error("No more profiles to view!");
          return;
        }
        setImage1Id(initialImages.image1_id);
        setImage2Id(initialImages.image2_id);
        setImage1Base64(initialImages.image1_base64);
        setImage2Base64(initialImages.image2_base64);
        posthog?.identify(initialImages.posthog_user_id, {});
      } catch (error) {
        console.error("Error initializing fit finder:", error);
        toast.error(
          "Failed to initialize fit finder. Please try again or contact support."
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (searchParams.get("sessionId")) {
      initializeFitFinder();
    } else {
      toast.error("Invalid session ID. Please check the URL and try again.");
    }
  }, [searchParams.get("sessionId")]);

  // useEffect(() => {
  //   const fetchPreferencesString = async () => {
  //     if (isFinished && searchParams.get("sessionId")) {
  //       try {
  //         const preferences = await getPreferencesString(
  //           searchParams.get("sessionId") as string
  //         );
  //         setPreferencesString(preferences);
  //       } catch (error) {
  //         console.error("Error fetching preferences string:", error);
  //         toast.error(
  //           "Failed to fetch preferences. Please try again or contact support."
  //         );
  //       }
  //     }
  //   };

  //   fetchPreferencesString();
  // }, [isFinished, searchParams]);

  const calculateProgress = (stage: number, comparisons: number) => {
    // Start at 10% to show initial progress
    const baseProgress = 10;

    // Allocate remaining 90% across stages
    // Stage 1: ~35% (10-45%)
    // Stage 2: ~30% (45-75%)
    // Stage 3: ~24% (75-99%)
    let progressPercentage = baseProgress;

    if (stage === 1) {
      // More gradual progress in stage 1 (up to 45%)
      progressPercentage += (comparisons / 12) * 35 * 0.75;
    } else if (stage === 2) {
      // Stage 1 complete (45%) + stage 2 progress
      progressPercentage = 45 + (comparisons / 8) * 30 * 0.85;
    } else if (stage === 3) {
      // Stage 2 complete (75%) + stage 3 progress
      progressPercentage = 75 + (comparisons / 8) * 24 * 0.99;
    }

    // Ensure we never hit 100% until actually complete
    return Math.min(progressPercentage, 99);
  };

  const handleImageClick = async (imageId: string) => {
    try {
      if (!searchParams.get("sessionId")) {
        throw new Error("Invalid session ID");
      }

      setImage1MostRcentlyClicked(imageId == image1Id);

      setIsLoading(true);
      const preferredImageId = imageId === image1Id ? image1Id : image2Id;
      const dispreferredImageId = imageId === image1Id ? image2Id : image1Id;
      const response = await submitPreferredAndGetNextImages(
        searchParams.get("sessionId") as string,
        preferredImageId,
        dispreferredImageId
      );

      if (response.status === "completed") {
        setIsFinished(true);
        setProgress(100);
        setIsLoading(false);
        return;
      }

      setComparisonsCount((prev) => prev + 1);
      if (response.current_stage !== currentStage) {
        setCurrentStage(response.current_stage);
        // Reset comparisons count when stage changes
        setComparisonsCount(0);
      }

      setProgress(
        calculateProgress(response.current_stage, comparisonsCount + 1)
      );

      setImage1Id(response.image1_id);
      setImage2Id(response.image2_id);
      setImage1Base64(response.image1_base64);
      setImage2Base64(response.image2_base64);
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Error handling image click:", error);
      toast.error(
        "Failed to fetch next images. Please try again or contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Initialize with 10% progress
  useEffect(() => {
    setProgress(10);
  }, []);

  return {
    image1Id,
    image2Id,
    image1Base64,
    image2Base64,
    currentStep,
    handleImageClick,
    progress,
    currentStage,
    isFinished,
    isLoading,
    image1MostRcentlyClicked,
    preferencesString,
  };
};

export default useFitFinder;
